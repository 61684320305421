interface Config {
  mode: string
  restApiUrl: string
  affiliateUrl: string
  contactUrl: string
  previewUrl: string
  mediaUrl: string
  firestoreBucketUrl: string
  imageServer: string
  externalScriptFallbackURL: string
}
const imageServerWhitelist = [
  'images.unsplash.com',
  'cdn.pixabay.com',
  'storage.googleapis.com',
  'firebasestorage.googleapis.com',
  'pixabay.com',
  'assets-staging.cdn.msgsndr.com',
  'staging.cdn.msgsndr.com',
  'staging.filesafe.space',
  'default.staging.filesafe.space',
  'assets.cdn.msgsndr.com',
  'cdn.msgsndr.com',
  'cdn.filesafe.space',
  'assets.cdn.filesafe.space',
  'img.youtube.com',
  'i.vimeocdn.com',
  'embed-ssl.wistia.com',
  'widgets.leadconnectorhq.com',
]

const config: { [key: string]: Config } = {
  development: {
    mode: 'dev',
    restApiUrl: 'https://staging.backend.leadconnectorhq.com',
    affiliateUrl: 'http://localhost:5086',
    contactUrl: 'https://staging.backend.leadconnectorhq.com/contacts',
    previewUrl: 'https://staging.gohighlevel.com',
    mediaUrl: 'https://hl-media-center.web.app',
    firestoreBucketUrl: 'https://storage.googleapis.com',
    imageServer: 'https://staging.images.leadconnectorhq.com',
    externalScriptFallbackURL:
      'https://storage.googleapis.com/builder-preview/form_embed/staging/am.js',
  },
  staging: {
    mode: 'staging',
    restApiUrl: 'https://staging.backend.leadconnectorhq.com',
    affiliateUrl: 'https://staging.backend.leadconnectorhq.com',
    contactUrl: 'https://staging.backend.leadconnectorhq.com/contacts',
    previewUrl:
      'https://affiliate-builder-dot-funnel-preview-dot-highlevel-staging.uc.r.appspot.com',
    mediaUrl: 'https://hl-media-center.web.app',
    firestoreBucketUrl: 'https://storage.googleapis.com',
    imageServer: 'https://staging.images.leadconnectorhq.com',
    externalScriptFallbackURL:
      'https://storage.googleapis.com/builder-preview/form_embed/staging/am.js',
  },
  production: {
    mode: 'production',
    restApiUrl: 'https://backend.leadconnectorhq.com',
    affiliateUrl: 'https://backend.leadconnectorhq.com',
    contactUrl: 'https://backend.leadconnectorhq.com/contacts',
    previewUrl: 'https://app.leadconnectorhq.com',
    mediaUrl: 'https://media-center-prod.firebaseapp.com',
    firestoreBucketUrl: 'https://storage.googleapis.com',
    imageServer: 'https://images.leadconnectorhq.com',
    externalScriptFallbackURL:
      'https://storage.googleapis.com/preview-production-assets/form_embed/prod/am.js',
  },
}

const override = false

const overrideConfig = {}
const envConfig = config[process.env.NODE_ENV || 'production']

export default override
  ? { ...envConfig, ...overrideConfig, imageServerWhitelist }
  : { ...envConfig, imageServerWhitelist }
